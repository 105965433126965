import {
  json,
  redirect,
  type MetaFunction,
  type LinksFunction,
  type LoaderFunctionArgs,
} from "@remix-run/node"
import type { MetaDescriptor } from "@remix-run/react"
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
} from "@remix-run/react"
import { redirects } from "../redirects.json"
import styles from "./styles/site.min.css?url"

export const meta: MetaFunction = (): Array<MetaDescriptor> => {
  const title =
    "Christian Internet Radio Featuring Biblical Preaching & Teaching | RefNet"
  const description =
    "Free 24-hour Christian internet radio. Listen to trustworthy preaching & teaching from trusted Bible teachers and theologians such as R.C. Sproul, Sinclair Ferguson, W. Robert Godfrey, John MacArthur, Stephen Nichols, Burk Parsons, Derek Thomas, & many more."

  return [
    { title },
    { charSet: "utf-8" },
    {
      name: "viewport",
      content:
        "width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=5, user-scalable=5",
    },
    { name: "description", content: description },
    { property: "og:title", content: title },
    { property: "og:type", content: "website" },
    {
      property: "og:image",
      content: "https://refnet.fm/images/facebook_thumb.jpg",
    },
    { property: "og:url", content: "https://refnet.fm" },
    { property: "og:site_name", content: "RefNet" },
    { property: "fb:admins", content: "Chris.Larson.73,john.a.cobb" },
    { property: "og:description", content: description },
    {
      name: "apple-itunes-app",
      content: "app-id=537706472, app-argument=https://refnet.fm",
    },
    {
      name: "detectify-verification",
      content: "cda3fe890e7958dd08f5009a4b655675",
    },
  ]
}

export const links: LinksFunction = () => [
  { rel: "shortcut icon", type: "text/html", href: "/images/favicon.ico" },
  {
    rel: "image_src",
    type: "image/jpeg",
    href: "https://refnet.fm/images/facebook_thumb.jpg",
  },
  { rel: "stylesheet", href: styles },
]

export async function loader({ request }: LoaderFunctionArgs) {
  // Get current URL path
  const url = new URL(request.url)
  const path = url.pathname

  // Check if path exists in redirects
  const foundRedirect = redirects.find((r) => r.source === path)
  if (foundRedirect) {
    return redirect(foundRedirect.destination)
  }

  return json({ GTM_CONTAINER_ID: process.env.GTM_CONTAINER_ID })
}

export default function App() {
  const { GTM_CONTAINER_ID } = useLoaderData<typeof loader>()

  return (
    <html lang="en">
      <head>
        <Meta />
        <Links />
        <script
          dangerouslySetInnerHTML={{
            __html: `
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${GTM_CONTAINER_ID}');
            `,
          }}
        ></script>
        <script src="//platform.twitter.com/widgets.js"></script>
      </head>
      <body>
        <main>
          {/* GOOGLE TAG MANAGER NOSCRIPT */}
          <noscript>
            <iframe
              title="gtm"
              src={`https://www.googletagmanager.com/ns.html?id=${GTM_CONTAINER_ID}`}
              height="0"
              width="0"
              style={{ display: "none", visibility: "hidden" }}
            />
          </noscript>
          <Outlet />
          <ScrollRestoration />
          <Scripts />

          <div id="video-overlay" className="popup-overlay">
            <section id="video" className="popup clearfix">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a id="video-close" className="popup-close-btn" href="#">
                ×
              </a>
              <iframe
                title="video-player"
                id="player"
                src=""
                width=""
                height=""
                frameBorder="0"
                allowFullScreen
              ></iframe>
            </section>
          </div>
        </main>
      </body>
    </html>
  )
}
